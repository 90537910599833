<template>
  <div class="filter-box py-5 px-4 mb-6">
    <h3 class="primary--text mb-3">
      <v-icon class="ml-1">mdi-filter</v-icon>البحث حسب
    </h3>
    <v-row align="center">
      <!-- QUERY -->
      <v-col cols="6">

        <v-text-field
            class="mb-5 my-2"
            rounded
            outlined
            solo
            hide-details
            clearable
            label="ابحث في عمليات المحفظة "
            v-model="filters.search"
        ></v-text-field>

        <v-text-field
            class="mb-5 my-2"
            rounded
            outlined
            solo
            hide-details
            clearable
            label="الاسم أو رقم الهاتف"
            v-model="filters.user_name"
        ></v-text-field>

        <v-menu
            ref="menu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
              rounded
              outlined
              hide-details
              clearable
              solo
              v-model="filters.datetime"
              label="اختر تاريخ الانشاء"
              readonly
              v-bind="attrs"
              v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
            v-model="filters.datetime"
            :max="
              new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            "
            min="1950-01-01"
        ></v-date-picker>
      </v-menu>

       </v-col>

      <!-- SEARCH BUTTON -->
      <v-col class="d-flex" cols="6">
        <v-spacer></v-spacer>
        <v-btn
            class="mr-2"
            @click="$router.replace({ query: { ...filters, page: 1 } })"
            large
            color="primary"
        >بحث</v-btn >
<!--        <v-btn-->
<!--          class="mr-2"-->
<!--          @click="handleFilters('search')"-->
<!--          large-->
<!--          color="primary"-->
<!--          >بحث</v-btn-->
<!--        >-->


      </v-col>
    </v-row>



  </div>
</template>


<script>
import { mapState, mapMutations, mapActions } from "vuex";

export default {
  name: "filters-box",

  data: function () {
    return {
      categoriesLoading: false,

      filters: {
        search: null,
        user_name: null,
        datetime: null,
      },
    };
  },

  created() {},

  computed: {},

  methods: {
    ...mapActions("wallet_transactions", ["fetchRecords"]),
    ...mapMutations("wallet_transactions", ["SET_FILTERS"]),
    handlingRoute(val) {
      this.$router
        .replace({
          query: { ...this.$route.query, page: val },
        })
        .catch(() => {});
    },
    handleFilters(val){
       this.SET_FILTERS({...this.$route.query , [val] : this.filters[val] , page : 1}); 
       this.$router.replace({query:{...this.$route.query , page:1} });
       if(this.$route.query.page == 1){
                this.fetchRecords();
        }
     },
     handleGetAll(){
      this.filters={active:null , search : '' , sub_category_id :''}
      this.$router.replace({query:{flag:1,search : '',active:0}})
      // this.fetchRecords('')
     },
  },
};
</script>
