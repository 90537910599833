<template>
  <v-dialog
    :value="showForm"
    @input="(v) => v || HIDE_FORM()"
    max-width="600px"
    scrollable
    persistent
  >
    <v-card>
      <!-- TITLE -->
      <v-card-title class="primary">
        <span class="headline">{{
            " إضافة عملية على المحفظة"
        }}</span>
      </v-card-title>

      <!-- INPUTS -->
      <v-card-text class="pt-5 pb-0">
        <v-container>
          <v-progress-linear
            v-if="formLoading"
            color="primary"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
          <div v-else>



            <v-text-field
                dense
                outlined
                label="العميل"
                class="mb-5 custom-text-field"
            v-model="keyword"
            :error-messages="errors.user_id"
            @input="searchUsers"
            ></v-text-field>
            <v-list v-if="users.length > 0" class="custom-list"> <!-- Apply custom class here -->
              <v-list-item
                  v-for="user in users"
                  :key="user.id"
                  @click="selectUser(user)"
              >
                <v-list-item-title>
                  {{ user.name }}
                  <span v-if="user.phone" class="phone-number">({{ user.phone }})</span> <!-- Apply custom class here -->
                </v-list-item-title>
              </v-list-item>
            </v-list>
<!--            <v-alert v-else-if="!loading && keyword.length > 0" outlined dense type="info">No users found</v-alert>-->

            <v-text-field
              dense
              outlined
              label=" وصف العملية"
              v-model="form.desc"
              :error-messages="errors.desc"
            ></v-text-field>

            <v-text-field
                dense
                outlined
                label="المبلغ"
                v-model="form.amount"
                :error-messages="errors.amount"
                type="number"
              step="1"
              min="0"
            ></v-text-field>



<!--            <v-autocomplete-->
<!--                dense-->
<!--                outlined-->
<!--                hide-details-->
<!--                clearable-->
<!--                label="نوع العملية"-->
<!--                class="mb-3"-->
<!--                item-text="title"-->
<!--                item-value="value"-->
<!--                v-model="form.type"-->
<!--                :error-messages="errors.type"-->
<!--                :items="types"-->
<!--            ></v-autocomplete>-->



            <!-- Date Inputs -->
            <div class="mb-5">
              <label>  وقت وتاريخ العملية</label>
              <input type="datetime-local" v-model="form.datetime" :error-messages="errors.datetime" required/>
              <div v-if="errors.datetime" class="mt-2">
                <span color="error">{{ errors.datetime }}</span>
              </div>
            </div>




          </div>
        </v-container>
      </v-card-text>

      <!-- ACTIONS -->
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :loading="formLoading"
          color="secondary"
          elevation="1"
          dark
          @click="create(form)"
          >حفظ</v-btn
        >
          <v-btn
          color="secondary"
          text
          elevation="1"
          dark
          @click="HIDE_FORM()"
          >الغاء</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import { mapState, mapMutations , mapActions} from "vuex";
import debounce from 'lodash/debounce';
import axios from 'axios'; // Import axios here

export default {
  name: "record-form",

  data: function () {
    return {
      users: [],
      keyword: '',
      loading: false,
      form: {
        user_id: null,
      },
      errors: {},

      formLoading: false,
      // types: [
      //   {title : "زيادة في الرصيد",value : "increase"},
      //   // {title : "انقاص في الرصيد",value: "decrease"}
      // ],

    };
  },
  // created(){
  //   this.fetchUsers();
  // },
  computed: {
    ...mapState("wallet_transactions", {
      editMode: (state) => state.editMode,
      showForm: (state) => state.showForm,
      formRecord: (state) => {
        return {
          id: state.formRecord.id,
          user_id: state.formRecord.user_id,
          desc: state.formRecord.desc,
          amount: state.formRecord.amount,
          // type: state.formRecord.type,
          datetime: state.formRecord.datetime,


        };
      },
    }),
  },

  watch: {
    formRecord(form) {
      this.form = form;
    },
  },

  methods: {
    ...mapMutations("wallet_transactions", ["HIDE_FORM"]),
    ...mapActions("wallet_transactions",["fetchRecords"]),

    create(form) {
      this.formLoading = true;

      this.$store
          .dispatch("wallet_transactions/create", { form })
        .then((response) => {
          this.$bus.$emit("showSnackbar", {
            text: "Wallet Transaction Added ",
            color: "success",
          });
          this.HIDE_FORM();
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
          for (let key in error.response.data.errors) {
            this.$bus.$emit("showSnackbar", {
              text: error.response.data.errors[key][0],
              color: "error",
            });
          }
        })
        .finally(() => {
          this.formLoading = false;
          this.fetchRecords()
        });
    },

    searchUsers: debounce(function () {
      if (this.keyword.length >= 1) {
        this.loading = true;
        axios.get("/admin/users/users_dropdown", {
          params: { search: this.keyword }
        })
            .then(response => {
              this.users = response.data;
              this.loading = false;
            })
            .catch(error => {
              console.error("Error fetching users:", error);
              this.loading = false;
            });
      } else {
        this.users = [];
      }
    }, 300),

    selectUser(user) {
      this.keyword = user.name;
      this.users = [];
      this.form.user_id = user.id; // Optionally assign the selected user's ID to your form data
    }
  },
};
</script>

<style scoped>
.custom-text-field {
  margin-bottom: 0 !important;; /* Adjust margin as needed */

}

.custom-list {
  margin-top: 0 !important; /* Adjust margin as needed */
  margin-bottom: 10px !important; /* Adjust margin as needed */

  background-color: white; /* Example color */
  box-shadow:0 4px 6px rgba(0, 0, 0, 0.1) !important;

}
</style>