<template>
  <div class="home page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi mdi-wallet</v-icon>
      عمليات المحفظة
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />

    <!-- CREATE / UPDATE FROM -->
    <record-form />

    <!-- UPDATE / NEW RECORD -->
    <filters-box @fetch="fetchRecords({ page: 1 })" />
    <div class="mb-3 mt-9 text-left">
      <v-btn
        large
        color="primary"
        elevation="0"
        @click="
          SET_FORM_RECORD({});
          SET_EDIT_MODE(false);
          SHOW_FORM();
        "
      >
        اضافة عملية
        <v-icon class="mr-2">mdi-plus</v-icon>
      </v-btn>
    </div>
    <v-chip right class="mb-2" label color="secondary">
      <v-icon> mdi-account-circle-outline </v-icon> عدد عمليات المحفظة
      {{ total_wallet_transactions }}</v-chip
    >
    <!-- TABLE -->
    <v-data-table
      item-key="id"
      class="elevation-1"
      :loading="table.loading"
      :headers="table.headers"
      :items="formattedRecords"
      loading-text="Downloading Data"
      no-data-text="No Data"
      disable-filtering
      disable-pagination
      disable-sort
      hide-default-footer >
      <!-- CONTROLS -->
      <template v-slot:item.controls="{ item }">
        <v-btn
          small
          icon
          color="secondary"
          class="mx-1"
          @click="
            SET_FORM_RECORD(item);
            SET_EDIT_MODE(true);
            SHOW_FORM();
          "
        >
          <v-icon>mdi-circle-edit-outline</v-icon>
        </v-btn>
      </template>

    </v-data-table>

    <!-- PAGINATION -->
    <div class="text-center mt-7">
      <v-pagination
          :value="+getFilter.page"
          circle
          total-visible="7"
          class="my-4"
          :length="Number(totalPages)"
          @input="changePage"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import RecordForm from "./RecordForm";
import { mapState, mapMutations , mapGetters} from "vuex";
import FiltersBox from "./FiltersBox.vue";
export default {
  name: "wallet_transactions",
  components: { RecordForm, FiltersBox },

  data: function () {
    return {
      // table
      table: {
        headers: [
          {
            value: "id",
            text: "مميز السجل",
          },
          {
            value: "user_name",
            text: "الاسم",
          },
          {
            value: "user_phone",
            text: "الهاتف",
            align: "left",
            class: "phone-column"
          },
          {
            value: "amount",
            text: "المبلغ",
          },
          // {
          //   value: "type",
          //   text: "النوع",
          // },
          {
            value: "balance_type",
            text: "مجاني أو مدفوع",
          },
          {
            value: "desc",
            text: "وصف العملية",
          },
          {
            value: "datetime",
            text: "وقت وتاريخ العملية",
          },
        ],
        loading: false,
      },
    };
  },

  computed: {
    ...mapState("wallet_transactions", [
      "records",
      "page",
      "totalPages",
      "filters",
      "total_wallet_transactions",
    ]),
    ...mapGetters("wallet_transactions", ["getFilter"] )

    ,formattedRecords() {
      return this.records.map(record => {
        return {
          ...record,
          // type: this.formatType(record.type),
          balance_type: this.formatBalanceType(record.balance_type),
          // user_phone: this.formatPhoneNumber(record.user_phone)
        };
      });
    }

  },
  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router.replace({ query: { ...route.query, page: 1 } });
          return;
        }
        this.SET_FILTERS({ ...this.getFilter,...route.query });
           this.fetchRecords();
       
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    ...mapMutations("wallet_transactions", [
      "SHOW_FORM",
      "SET_EDIT_MODE",
      "SET_FORM_RECORD",

      "SHOW_RECORD_FORM",

      "SET_FILTERS",
    ]),
    
    changePage(val){
      this.$router
        .replace({
          query: { ...this.$route.query, page: val },
        })
        .catch(() => {});
    },
    fetchRecords() {
      this.table.loading = true;
      // // console.log(page);

      const userId = this.$route.query.userId;
      const page = this.$route.query.page || 1;


      this.$store
        .dispatch("wallet_transactions/fetchRecords", { userId, page })
        .catch((error) => {
          this.$bus.$emit("showSnackbar", {
            text: "Error while getting Data",
            color: "accent",
          });
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    // formatType(type) {
    //   return type === 'credit' ? 'سحب' : type === 'debit' ? 'ايداع' : type;
    // },
    formatBalanceType(balance_type) {
      return balance_type === 'free' ? 'مجاني' :balance_type === 'paid' ? 'مدفوع' : balance_type;
    },
    formatPhoneNumber(phoneNumber) {
      // Check if the phone number starts with '+'
      if (!phoneNumber.startsWith('+')) {
        // If it doesn't start with '+', prepend it
        phoneNumber = '+' + phoneNumber;
      }
      return phoneNumber;
    }

  },
};
</script>
<style scoped>
/* Your scoped styles */
.phone-column {
  text-align: left !important;
}
</style>